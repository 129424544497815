// Test page with a button that when is clicked render the src prop in an iframe element.
// This is only for testing purposes, so the src prop can be any url.

import { useState } from "react"

export const IframeTesterPage = () => {
  const [src, setSrc] = useState<string>("")
  const [iFrameSrc, setIframeSrc] = useState<string>("")

  return (
    <div>
      <input
        style={{ width: "500px", marginRight: "10px" }}
        type="text"
        value={src}
        onChange={(e) => setSrc(e.target.value)}
      />
      <button
        style={{ marginBottom: "20px" }}
        onClick={() => setIframeSrc(src)}
      >
        Render
      </button>
      <br />
      <iframe
        title="Document Signing"
        src={iFrameSrc}
        width="100%"
        height="800px"
        referrerPolicy="strict-origin-when-cross-origin" // This is to avoid the iframe to be blocked by the browser
      />
    </div>
  )
}
